import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';

import { useAuthContext } from "../AuthContext";

export function AuthHeader(): React.ReactElement {
    const { logout } = useAuth0();

    const authData = useAuthContext();
    return authData ? (<div>
        <i>Username: { authData ? `${authData?.name} (${authData?.nickname})` : "null" }</i>
        &nbsp;
        <Button variant="contained" onClick={() => logout()}>Logout</Button>
    </div>) : <></>
}