import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';


export function Login(): React.ReactElement {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
    <h1>You must log in to view this site</h1>
    <Button variant="contained" onClick={() => loginWithRedirect()}>Log In</Button>
    </>
  )
};