
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import debounce from "lodash/debounce";

import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';

import { ControllerForm, ControllerFormValues } from "./ControllerForm";
import { ViewFrame } from "./ViewFrame";

export interface ControllerProps {
    apiServer: string,
}

interface GenState {
    prompt?: string,
    neg_prompt?: string,
}

export function Controller(props: ControllerProps): React.ReactElement {
    const { id } = useParams();
    const [genState, setGenState] = useState<GenState>({});

    const updateSpeed = async (kind: string, newValue: number) => {
        const uri = `${props.apiServer}/gen/speed/${kind}`;
        const post = {
            frames: newValue,
            job_id: id,
        };
        // TODO: handle errors
        try {
            await fetch(uri, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(post),
            })    
        } catch (e: any) {
            console.log(e)
        }
    };
    const [promptSteps, setPromptSteps] = React.useState(80);
    const [noiseSteps, setNoiseSteps] = React.useState(80);
    const [debounceUpdateSpeed] = React.useState(() =>
        debounce(updateSpeed, 300, {
            leading: false,
            trailing: true
        })
    );


    // Not sure why so complex, but get the state on open
    useEffect(() => {
            fetch(`${props.apiServer}/gen/${id}/state`)
                                .then(res=> res.json())
                                .then(state => {
                                    console.log(state);
                                    setGenState(state);
                                    setPromptSteps(state.prompt_steps);
                                    setNoiseSteps(state.noise_steps);
                                });
        }, [id, props.apiServer]);

    const onSubmit = async (data: ControllerFormValues) => {
        const post = {
            ...data,
            job_id: id,
        };
        // TODO: handle errors
        try {
            await fetch(`${props.apiServer}/gen/prompt`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(post),
            })    
        } catch (e: any) {
            console.log(e)
        }
    }; 
    const stop = async () => {
        const post = {
            job_id: id,
        };
        // TODO: handle errors
        try {
            await fetch(`${props.apiServer}/jobs/stop`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(post),
            })    
        } catch (e: any) {
            console.log(e)
        }
    }

    const onPromptStep = (event: any, value: number | number[]) => {
        const val = Array.isArray(value) ? value[0] : value;
        setPromptSteps(val);
        debounceUpdateSpeed("prompt", val);
    }

    const onNoiseStep = (event: any, value: number | number[]) => {
        const val = Array.isArray(value) ? value[0] : value;
        setNoiseSteps(val);
        debounceUpdateSpeed("noise", val);
    }

    return (<div>
        <ViewFrame apiServer={props.apiServer} jobID={id!} width={256} height={256} />
        <Button variant="contained" onClick={stop} color="error">Stop Job</Button>
        <ControllerForm onSubmit={onSubmit} prompt={genState.prompt} neg_prompt={genState.neg_prompt} />
        <p>Prompt Steps</p>
        <Slider value={promptSteps} min={1} max={100} aria-label="Prompt Steps" valueLabelDisplay="on" onChange={onPromptStep} />
        <p>Noise Steps</p>
        <Slider value={noiseSteps} min={1} max={100} aria-label="Noise Steps" valueLabelDisplay="on" onChange={onNoiseStep} color="secondary" />
    </div>)
}