import React from 'react';
import { createContext, useContext } from 'react';
import { useAuth0, User } from "@auth0/auth0-react";

export interface AuthProviderProps {
    children: React.ReactElement[] | React.ReactElement,
}

export const AuthContext = createContext<User | undefined>(undefined);

export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }: AuthProviderProps) {
  // const { user, isAuthenticated, isLoading } = useAuth0();
  const { user } = useAuth0();

  return (
    <AuthContext.Provider value={user}>
      {children}
    </AuthContext.Provider>
  );
}