import { useForm } from "react-hook-form";
import React from "react";
import { useMemo, useEffect } from "react";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

export interface ControllerFormProps {
    onSubmit: (data: ControllerFormValues) => unknown | Promise<unknown>,
    prompt?: string,
    neg_prompt?: string,
}

export interface ControllerFormValues {
    prompt: string,
    neg_prompt: string,
}

export function ControllerForm(props: ControllerFormProps): React.ReactElement {
    const {
        register,
        handleSubmit,
        reset,
        // watch,
        formState: { errors }
    } = useForm<ControllerFormValues>({
        defaultValues: useMemo(() => {
            return {
                prompt: props.prompt || "",
                neg_prompt: props.neg_prompt || "",
            };
        }, [props])
    });

    useEffect(() => {
        reset({
            prompt: props.prompt || "",
            neg_prompt: props.neg_prompt || "",
        });
    }, [reset, props.prompt, props.neg_prompt]);

    return (<form onSubmit={handleSubmit(props.onSubmit)}>
        <FormControl fullWidth style={{marginTop: "10px"}}>
        <TextField 
                id="prompt-field"
                label="Prompt"
                error={!!errors?.prompt}
                helperText={errors?.prompt?.type === "required" ? "Required Field" : errors?.prompt?.type === "minLength" ? "must be at least 3 characters" : ""}
                multiline
                {...register("prompt", {
                        required: true,
                        minLength: 3,
                    })}
                InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                        minRows: 3,
                        minCols: 80,
                    }
                }}
                />
        <br/>
        <TextField
                    label="Negative Prompt"
                    id="neg-prompt-field"
                    multiline
                    {...register("neg_prompt", {
                        required: false,
                    })}
                    InputProps={{
                        inputComponent: TextareaAutosize,
                        inputProps: {
                            minRows: 3,
                            minCols: 80,
                        }
                    }}    
            />
        <br/>
        <Button variant="contained" type="submit">Update Prompt</Button>
        </FormControl>
    </form>)
}