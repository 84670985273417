import React from "react";

// TODO: pass this in as a prop, or better context!
import { config } from '../config';

export interface ViewFrameProps {
    apiServer: string,
    jobID: string,
    width: number | string, // in case we want "100%"
    height: number | string,
}

export function ViewFrame(props: ViewFrameProps): React.ReactElement {
    const publicUrl = config.publicUrl;
    return (<div>
        <iframe title="nf-viewer" src={`${publicUrl}/viewer.html?apiServer=${props.apiServer}&id=${props.jobID}`} width={props.width} height={props.height} />
    </div>)
}