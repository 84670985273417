import { useForm } from "react-hook-form";
import React from "react";

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Typography } from "@mui/material";

export interface JobFormProps {
    onSubmit: (data: JobFormValues) => unknown | Promise<unknown>,
}

export interface JobFormValues {
    model: string,
    quality: string,
    prompt: string,
    neg_prompt: string,
}

export function JobForm(props: JobFormProps): React.ReactElement {
    const {
        register,
        handleSubmit,
        // watch,
        formState: { errors }
    } = useForm<JobFormValues>();

    return (<form onSubmit={handleSubmit(props.onSubmit)}>
        <FormControl fullWidth style={{marginTop: "10px"}}>
        <TextField 
                id="prompt-field"
                label="Prompt"
                error={!!errors?.prompt}
                helperText={errors?.prompt?.type === "required" ? "Required Field" : errors?.prompt?.type === "minLength" ? "must be at least 3 characters" : ""}
                defaultValue="fully saturated primary colours of a motif representing fear and hopelessness amidst the churning lust"
                multiline
                {...register("prompt", {
                        required: true,
                        minLength: 3,
                    })}
                InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                        minRows: 3,
                        minCols: 80,
                    }
                }}
                />
        <br/>
        <TextField
                    label="Negative Prompt"
                    id="neg-prompt-field"
                    multiline
                    {...register("neg_prompt", {
                        required: false,
                    })}
                    InputProps={{
                        inputComponent: TextareaAutosize,
                        inputProps: {
                            minRows: 3,
                            minCols: 80,
                        }
                    }}    
            />
        <br/>
        <Typography id="model-select-label">Model</Typography>
        <Select {...register("model")} labelId="model-select-label" 
            id="model-select">
            <MenuItem value="DreamShaper">DreamShaper</MenuItem>
            <MenuItem value="RealisticVision">RealisticVision</MenuItem>
            <MenuItem value="kohaku">Kohaku (Anime)</MenuItem>
            <MenuItem value="biloutteDream">Biloutte Dream (Psychedelic Portrait)</MenuItem>
            <MenuItem value="CreatureDesign">Creature Design</MenuItem>
            <MenuItem value="Dynavision">Dynavision (Realistic + Anime)</MenuItem>
            <MenuItem value="RealCartoon">RealCartoon (Realistic + Anime)</MenuItem>
            <MenuItem value="CrazyCosmicColorscape">Crazy Cosmic Colorscape (Alien Planets)</MenuItem>
            <MenuItem value="PsychedelicRoom">Psychedelic Room</MenuItem>
        </Select>
        <br/>
        <Typography id="quality-select-label">Quality</Typography>
        <Select {...register("quality")} labelId="quality-select-label" 
            id="quality-select">
            <MenuItem value="fast">Fast</MenuItem>
            <MenuItem value="good">Good</MenuItem>
            <MenuItem value="super">Super (very slow)</MenuItem>
        </Select>
        <br/>
        <Button variant="contained" type="submit">Launch Job</Button>
        </FormControl>
    </form>)
}