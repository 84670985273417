import './App.css';
import { config } from './config';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Controller } from './components/Controller';
import { Viewer } from './components/Viewer';
import { Home } from './components/Home';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LaunchJob } from './components/LaunchJob';
import { AuthProvider } from './AuthContext';
import { AuthHeader } from './components/AuthHeader';

import { useAuthContext } from "./AuthContext";
import { Login } from './components/Login';
import { Outlet } from 'react-router-dom';

function RequireLogin(): React.ReactElement {
  const authData = useAuthContext();
  return authData ? (<Outlet/>) : (<Login/>);
}

const theme = createTheme({});
const queryClient = new QueryClient({});

function App() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
        <div className="App">
          <AuthHeader />
          <Routes>
            <Route path="/viewer/:id" element={<Viewer apiServer={config.apiServer} />} />
            <Route element={<RequireLogin />} >
              <Route path="/" element={<Home apiServer={config.apiServer} />} />
              <Route path="/new_job" element={<LaunchJob apiServer={config.apiServer} />} />
              <Route path="/controller/:id" element={<Controller apiServer={config.apiServer} />} />
            </Route>
          </Routes>
          <ReactQueryDevtools initialIsOpen={isDev} />
        </div>
        </AuthProvider>
        </QueryClientProvider>
    </ThemeProvider>

  );
}

export default App;
