import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";


import { JobForm, JobFormValues } from "./JobForm";

// fully saturated primary colours of a motif representing fear and hopelessness amidst the churning lust

export interface LaunchJobProps {
    apiServer: string,
}

export function LaunchJob(props: LaunchJobProps): React.ReactElement {
    const { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = async (data: JobFormValues) => {
        const post = {
            job_id: id,
            noise_steps: 60,
            prompt_steps: 40,
            ...data,
        };
        // TODO: handle errors
        try {
            await fetch(`${props.apiServer}/jobs/launch`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(post),
            });
            navigate("/");
        } catch (e: any) {
            console.log(e)
        }
    }; 

    return (<div>
        <JobForm onSubmit={onSubmit} />
    </div>)
}
